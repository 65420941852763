.certificates .wrapper-certificates img {
  height: 25rem;
  border-radius: 25px;
  box-shadow: 0rem 3rem 0 1rem #305ca7;
  margin-right: 5rem;
}
.certificates .wrapper-certificates .text {
  font-weight: bold;
  font-size: 1.3rem;
  color: #737373;
}
.certificates .wrapper-certificates .wrapper-divs .text {
  color: #000000;
  font-weight: normal;
}
.certificates .wrapper-certificates a {
  width: 45%;
  text-decoration: none;
}
.certificates .wrapper-certificates .final-a {
  width: 25%;
}
.certificates .wrapper-certificates a.button-contact {
  background-color: #6b97cf;
  border: 1px solid #6b97cf;
  font-weight: bold;
  border-radius: 15px;
  font-size: 1.2rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}
.certificates .wrapper-certificates a.button-contact {
  color: #ffffff;
  vertical-align: middle;
  padding: 0.8rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}
.certificates .wrapper-certificates a.button-contact:hover {
  border: 1px solid #6b97cf;
  background-color: #ffffff;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}
.certificates .wrapper-certificates a.button-contact:hover {
  color: #6b97cf;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}
.certificates .text {
  font-size: 1.4rem;
}
.certificates .wrapper-divs .wrap-card {
  background-color: #ffffff;
  border-radius: 25px;
  border: 2px solid #6b97cf;
  box-shadow: 5rem 3rem 0 1rem #305ca7, -5rem 3rem 0 1rem #305ca7;
}
.certificates .wrapper-divs .title {
  font-weight: bold;
  font-size: 1.3rem;
}
.certificates .wrapper-divs .text {
  font-size: 1rem;
}
.wrapper-main:has(.certificates) ~ footer {
  padding-top: 1rem !important;
}
@media screen and (max-width: 992px) {
  .certificates {
    width: 100%;
  }
  .certificates .container-title {
    padding-bottom: 0 !important;
  }
  .certificates .wrapper-certificates img {
    height: 18rem;
    margin-right: 0 !important;
    width: 100%;
  }
  .certificates .wrapper-certificates .text {
    padding-top: 6rem;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .certificates .wrapper-certificates .wrapper-divs .text {
    padding-top: 0 !important;
  }
  .certificates .wrapper-divs .wrap-card {
    box-shadow: 0rem -1rem 0 1rem #305ca7, 0rem 4rem 0 1rem #305ca7;
    margin-bottom: 2rem;
  }
  .wrapper-certificates .d-flex .button-contact {
    width: 65%;
  }
}
@media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
  .certificates .wrapper-certificates .text {
    padding-left: 0rem !important;
    padding-right: 0rem !important;
  }
  .certificates .wrapper-certificates .text .button-contact {
    width: 90% !important;
  }
  .certificates .wrapper-divs .wrap-card {
    box-shadow: 1rem 3rem 0 1rem #305ca7, -1rem 3rem 0 1rem #305ca7;
  }
}
