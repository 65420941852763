.font-aboutUs {
  font-size: 1.3rem;
}
.border-container {
  background-color: #ffffff;
  border-radius: 25px;
  border: 2px solid #6b97cf;
  padding: 2rem;
}
.border-container .title {
  font-weight: bold;
  font-size: 2rem;
}
.mission {
  margin-top: 10rem;
}
.about-us .values-container img {
  height: 5rem;
  margin-bottom: 10px;
}
.about-us .values-container .title {
  font-weight: bold;
  font-size: 2rem;
  margin-bottom: 3px;
}
.about-us .values-container .font-values {
  font-size: 1rem;
}
.about-us .values-container .custom-right-padding {
  padding-left: 7rem !important;
  padding-right: 8rem !important;
}
.about-us .values-container .custom-left-padding {
  padding-left: 8rem !important;
  padding-right: 7rem !important;
}
.governance-container img {
  height: 10rem;
  width: 10rem;
  margin-bottom: 1rem;
}
.governance-container .bold-name {
  font-weight: bold;
}
.governance-container a {
  font-weight: normal;
  text-decoration: underline;
}
.governance-container .card-gov img {
  background-color: #ffffff;
  border-radius: 25px;
  border: 2px solid #6b97cf;
}
.governance-container {
  background-color: #e9e9e9;
  border-radius: 25px;
  margin-top: 9rem;
}
.governance-container .title {
  font-size: 2.7rem;
  font-weight: bold;
}
.governance-container .row-investment .card-gov,
.governance-container .row-governance .card-gov {
  padding-right: 6rem !important;
  padding-left: 6rem !important;
}
.mission,
.vision {
  box-shadow: 0rem 3rem 0 1rem #305ca7;
}
.governance-container a.button-contact {
  width: 25%;
  text-decoration: none;
}
.governance-container a.button-contact {
  background-color: #6b97cf;
  border: 1px solid #6b97cf;
  font-weight: bold;
  border-radius: 15px;
  font-size: 1.2rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}
.governance-container a.button-contact {
  color: #ffffff;
  vertical-align: middle;
  padding: 0.8rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}
.governance-container a.button-contact:hover {
  border: 1px solid #6b97cf;
  background-color: #ffffff;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}
.governance-container a.button-contact:hover {
  color: #6b97cf;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}
@media screen and (max-width: 992px) {
  .about-us {
    width: 100%;
  }
  .mission,
  .vision {
    margin-right: 1rem;
    margin-left: 1rem;
    box-shadow: 0rem 3rem 0 0.5rem #305ca7;
  }
  .wrapper-struttura-gruppo .font-aboutUs {
    margin-right: 1rem;
    margin-left: 1rem;
  }
  .values-container .d-lg-4,
  .about-us .values-container .custom-right-padding,
  .about-us .values-container .custom-left-padding {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
    padding-bottom: 4rem;
  }
  .values-container .d-lg-flex.pb-5 {
    padding-bottom: 0 !important;
  }
  .governance-container .d-lg-flex {
    display: grid !important;
    grid-template-columns: repeat(2, 1fr);
  }
  .governance-container .row-investment .card-gov,
  .governance-container .row-governance .card-gov,
  .team1 .col-lg-3,
  .team2 .col-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
    padding-bottom: 3rem;
  }
  .governance-container {
    margin-top: 2rem !important;
  }
  .team2 {
    padding-top: 0 !important;
  }
  .governance-container a.button-contact {
    width: 65%;
  }
}
