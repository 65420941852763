@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

body {
  font-family: "Montserrat", serif !important;
}

.app-wrapper {
  background-color: transparent;
  padding-top: 7rem;
}

.wrapper-main {
  display: flex;
  justify-content: center;
}

.wrapper-main .fund-management,
.wrapper-main .certificates,
.wrapper-main .asset-management,
.wrapper-main .financial-consulting,
.wrapper-main #contactUs,
.wrapper-main .about-us,
.wrapper-main #home {
  z-index: 10;
}

.ibm-plex-sans-thin {
  font-family: "IBM Plex Sans", serif;
  font-weight: 100;
  font-style: normal;
}

.ibm-plex-sans-extralight {
  font-family: "IBM Plex Sans", serif;
  font-weight: 200;
  font-style: normal;
}

.ibm-plex-sans-light {
  font-family: "IBM Plex Sans", serif;
  font-weight: 300;
  font-style: normal;
}

.ibm-plex-sans-regular {
  font-family: "IBM Plex Sans", serif;
  font-weight: 400;
  font-style: normal;
}

.ibm-plex-sans-medium {
  font-family: "IBM Plex Sans", serif;
  font-weight: 500;
  font-style: normal;
}

.ibm-plex-sans-semibold {
  font-family: "IBM Plex Sans", serif;
  font-weight: 600;
  font-style: normal;
}

.ibm-plex-sans-bold {
  font-family: "IBM Plex Sans", serif;
  font-weight: 700;
  font-style: normal;
}

.ibm-plex-sans-thin-italic {
  font-family: "IBM Plex Sans", serif;
  font-weight: 100;
  font-style: italic;
}

.ibm-plex-sans-extralight-italic {
  font-family: "IBM Plex Sans", serif;
  font-weight: 200;
  font-style: italic;
}

.ibm-plex-sans-light-italic {
  font-family: "IBM Plex Sans", serif;
  font-weight: 300;
  font-style: italic;
}

.ibm-plex-sans-regular-italic {
  font-family: "IBM Plex Sans", serif;
  font-weight: 400;
  font-style: italic;
}

.ibm-plex-sans-medium-italic {
  font-family: "IBM Plex Sans", serif;
  font-weight: 500;
  font-style: italic;
}

.ibm-plex-sans-semibold-italic {
  font-family: "IBM Plex Sans", serif;
  font-weight: 600;
  font-style: italic;
}

.ibm-plex-sans-bold-italic {
  font-family: "IBM Plex Sans", serif;
  font-weight: 700;
  font-style: italic;
}

.picker-lang-andromeda * {
  color: #000000;
}

.picker-lang-andromeda button {
  background: none;
  color: #000000;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.picker-lang-andromeda button.active {
  color: #6b97cf;
}
.show-menu .picker-lang-andromeda button,
.show-menu .picker-lang-andromeda span {
  color: #ffffff;
}

.show-menu .picker-lang-andromeda button.active {
  color: #6b97cf;
}
.not-found {
  height: 35rem;
  padding-top: 10rem !important;
}

@media screen and (max-width: 992px) {
  video {
    width: 100%;
    height: 400px !important;
  }
  .picker-lang-andromeda {
    font-size: 25px;
  }
  .picker-lang-andromeda button,
  .picker-lang-andromeda span {
    color: #ffffff;
  }
}

@media screen and (max-width: 1150px) {
  .iub__us-widget,
  .iub__us-widget__link {
    justify-content: center !important;
  }
  .iub__us-widget {
    display: block;
  }
  .iub__us-widget__link--privacy-choices::after {
    margin-right: 0.8em !important;
  }
}

@media screen and (min-width: 992px) {
  .iub__us-widget__wrapper {
    justify-content: center;
  }
}
