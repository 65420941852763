.results-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.wrapper-main:has(.wrapper-documents) ~ footer {
  padding-top: 1rem !important;
}
.wrapper-documents .input-search {
  border-radius: 25px;
  border: 2px solid #6b97cf;
  padding: 1rem 1rem 1rem 4rem;
  width: 50%;
  background: url(../assets/icons/search_icon.png) no-repeat scroll 1rem 0.6rem;
  background-size: 2.4rem;
}
.wrapper-documents .card-documents {
  border: 1px solid #6b97cf;
  border-radius: 25px;
  padding: 2rem;
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 15rem;
}
.wrapper-documents .card-documents .title-documents {
  display: block;
  padding-bottom: 2rem;
  font-size: 1.7rem;
  font-weight: bold;
}
.wrapper-documents .card-documents .button-contact {
  float: right;
  vertical-align: middle;
  padding: 0.8rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
  background-color: #6b97cf;
  border: 1px solid #6b97cf;
  font-weight: bold;
  border-radius: 15px;
  font-size: 1.2rem;
  color: #ffffff;
  text-decoration: none;
  width: 40%;
  margin-left: auto;
  margin-right: 0;
}
.wrapper-documents .card-documents .button-contact:hover {
  border: 1px solid #6b97cf;
  background-color: #ffffff;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
  color: #6b97cf;
}
@media screen and (max-width: 992px) {
  .wrapper-documents .card-documents {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
    display: grid;
    height: auto;
  }
  .results-wrapper {
    display: block;
  }
  .wrapper-documents .input-search {
    width: 75%;
  }
}
