.actions button {
  border: 1px solid #6b97cf;
  background-color: #ffffff;
  color: #6b97cf;
  font-weight: 600;
  padding: 14px;
  width: 300px;
  border-radius: 30px;
}
.actions button:hover {
  color: #ffffff;
  background-color: #6b97cf;
}
.popup-content {
  border-radius: 5px;
}

@media screen and (max-width: 1150px) {
  .popup-content {
    width: 80% !important;
  }
  .actions button {
    margin-bottom: 2rem;
    width: 80%;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
