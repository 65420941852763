#contactUs img {
  height: 4rem;
}
.wrapper-main:has(#contactUs) ~ footer {
  padding-top: 1rem !important;
}
.font-appuntamento {
  font-size: 0.8rem;
  font-weight: bold;
}
.font-bold {
  font-weight: bold;
}
