.goTopButton {
  position: fixed;
  bottom: 70px;
  right: 16px;
  z-index: 100;
}
.goTopButton button {
  height: 35px;
  width: 35px;
  border-radius: 25px;
  font-size: 1.3rem;
  font-weight: bold;
  background-color: #ffffff;
  color: #6b97cf;
  border: 1px solid #6b97cf;
}
